const ExamActivityLogs = Object.freeze({ 
	LOGIN: 1, 
	START: 2, 
	WEBCAM: 3,
	CANDIDATE_IMAGE: 4,
	IDENTITY_IMAGE: 5
});

const TestEnum = Object.freeze({ 
	START: 1, 
	END: 2, 
});

export { ExamActivityLogs, TestEnum };