<template>
  <div class="page-content header-clear-large h-100">
    <div class="good-luck-container">
      <i class="fa fa-check-circle fa-2x"></i>
      <div>Exam Finished. Good luck!</div>
    </div>
    <div class="question-detail-container">
      <div>
        <span>Total Questions </span>
        <div class="fa-2x">{{ totalQuestion }}</div>
      </div>
      <div>
        <span>Answered </span>
        <div class="fa-2x">{{ answered }}</div>
      </div>
      <div>
        <span>Not Answered </span>
        <div class="fa-2x">{{ notAnswered }}</div>
      </div>      
    </div>
  </div>
</template>

<script>

export default {
  name: "ExamFinish",
  props: {
    msg: String
  },
  computed : {
    totalQuestion() {
      return this.$route.params.totalQuestion;
    },
    answered() {
      return this.$route.params.answered;
    },
     notAnswered() {
      return parseInt(this.$route.params.totalQuestion) - parseInt(this.$route.params.answered);
    },   
  }
};
</script>
<style scoped>

.good-luck-container {
  text-align: center;
  color: green;
  font-size: 25px;
}
.good-luck-container > div {
  padding-top: 15px;
}

.question-detail-container {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  border: 1px dashed #ccc;
  padding: 20px;
}

.question-detail-container .fa-2x {
  padding-top: 10px;
}

.page-content.header-clear-large {
  padding: 85px;
}

@media (max-width: 767px) {
 .page-content.header-clear-large {
  padding: 85px 20px;
 }
 .question-detail-container {
   padding: 10px;
   font-size: 13px !important;
   line-height: 20px;
   flex-direction: column;
 }  
}
</style>