import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Exam from '@/components/Exam/Exam'
import Login from '@/components/Login'
import ExamFinish from '@/components/ExamFinish'
import Identity from '@/components/Identity'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Exam',
    component: Exam
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Identity',
    name: 'Identity',
    component: Identity
  },
  {
    path: '/ExamFinish/:totalQuestion/:answered',
    name: 'ExamFinish',
    component: ExamFinish
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name !== 'Login' && !store.getters.token)
    next({name: 'Login'});
  else if(to.name === 'Login' && store.getters.token)      
    next({name: 'Identity'});  
  else
    next()
  // to and from are both route objects. must call `next`.
});
router.afterEach((to, from) => {
  if(to.name === 'Exam') {
    setTimeout(function(){
      if(typeof windowResizeEvent !== 'undefined')
        windowResizeEvent();
    }, 1000);
  }
});

export default router
