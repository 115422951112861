import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Comment this part at the time of build
// import framworkStyle from './assets/styles/framework.css'
// import mainStyle from './assets/styles/style.css'
// import font from './assets/fonts/css/fontawesome-all.min.css'
// Vue.use(framworkStyle);
// Vue.use(mainStyle);
// Vue.use(font);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
