import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('auth') || '',
    user:  (sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : ''),
    schoolcode: sessionStorage.getItem('schoolcode') || ''
  },
  getters: {
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },
    schoolcode(state) {
      return state.schoolcode;
    }
  },
  mutations: {
    setToken (state, value) {
      state.token = value;
    },
    setUser (state, value) {
      state.user = value;
    },
    setSchoolcode (state, value) {
      state.schoolcode = value;
    }
  },
  actions: {
    setToken(context, value){
      sessionStorage.setItem('auth', value);
      context.commit('setToken', value);
    },
    setUser(context, value){
      sessionStorage.setItem('user',JSON.stringify(value));
      context.commit('setUser', value);
    },
    setSchoolcode(context, value){
      sessionStorage.setItem('schoolcode',value);
      context.commit('setSchoolcode', value);
    }
  },
  modules: {
  }
})
