<template>
  <div class="content-wrapper h-100">
    <div class="container h-100">
      <!-- page content -->
      <div class="page-content h-100">
        <div v-if="examDetails" class="identity-container content content-box round-medium shadow-huge">
            
            <div class="font-18 bottom-20">
                Please capture your and your identity proof's image by keeping indetity on front of webcam
            </div>

            <div id="candidate_container" class="" style="display: flex; overflow: hidden; width: 500px;">
                <div id="candidate_video_container" class="text-center">
                    <div>
                        <h3 class="">Capture Candidate's Photo</h3>
                    </div>
                    <div>
                        <video id='candidate_video' class="round-medium" autoplay disablePictureInPicture width="500" height="375"></video>
                    </div>
                    <button type="button" v-on:click="captureCandidate" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0"><i class="fa fa-camera right-5"></i>Capture Candidate</button>
                </div>

                <div id="candidate_canvas_container" class="text-center">
                    <div>
                        <h3 class="">Candidate's Photo</h3>
                    </div>
                    <canvas id="candidate_canvas" class="round-medium"></canvas>        
                    <button type="button" v-on:click="captureCandidateAgain" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-5 right-5"><i class="fa fa-arrow-left right-5"></i>Capture Again</button>
                    <button type="button" v-on:click="saveCandidate" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0"><i class="fa fa-save right-5"></i>Save Candidate</button>
                </div>

                <div id="identity_video_container" class="text-center">
                    <div>
                        <h3 class="">Capture Identity's Photo</h3>
                    </div>
                    <div>
                        <video id='identity_video' class="round-medium" autoplay disablePictureInPicture width="500" height="375"></video>
                    </div>
                    <button type="button" v-on:click="captureIdentity" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0"><i class="fa fa-camera right-5"></i>Capture Identity</button>
                </div>

                <div id="identity_canvas_container" class="text-center">
                    <div>
                        <h3 class="">Identity's Photo</h3>
                    </div>
                    <canvas id="identity_canvas" class="round-medium"></canvas>        
                    <button type="button" v-on:click="captureIdentityAgain" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-5 right-5"><i class="fa fa-arrow-left right-5"></i>Capture Again</button>
                    <button type="button" v-on:click="saveIdentity" 
                    class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0"><i class="fa fa-save right-5"></i>Save Identity</button>
                </div>
            </div>

            <div class="font-15 text-right" style="width: 100%;">
                Please skip if already captured once
                <button type="button" v-on:click="skipToExam" class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0 left-10">Skip</button>
            </div>

        </div>
        <div v-else class="identity-container no-exam-found content content-box round-medium shadow-huge">
          <img src="../assets/images/education_evaluation_exam_online_icon.png" width="200">
          <h2 class="text-center">Exam not started yet. Please wait till exam start or check by refreshing the page on click of refresh button</h2>
          <div>
            <button type="button" v-on:click="refreshExam" class="button shadow-huge button-m button-round-small bg-blue2-dark bottom-0 left-10">Refresh</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import { ExamActivityLogs } from '../enum'

export default {
  name: "Identity",
  data() {
    return {  
      location: '',
      ipAddress: '',
      noExamFound: false,
      examDetails: ''
    };
  },
  created() {    
    let self = this;
    navigator.geolocation.getCurrentPosition(function (geo) {
      self.location = geo.coords.latitude + ',' + geo.coords.longitude;
    });

    axios.get('https://api.ipify.org/?format=json').then(function(res){ self.ipAddress = res.data.ip });    

    self.getExamDetails();
  },
  mounted() {
    if(window.innerWidth < 500) {
        let candidate_video = document.getElementById("candidate_video");
        let identity_video =  document.getElementById("identity_video");

        candidate_video.width=300;
        candidate_video.height=225;

        identity_video.width=300;
        identity_video.height=225;

        let candidate_container=  document.getElementById('candidate_container')
        candidate_container.style.width='300px'
    }
  },
  methods: {
  refreshExam: function(){
    this.$parent.showLoader = true;
    window.location.reload();
  },

    getExamDetails: function(e) {
      let self = this;
      self.$parent.showLoader = true;
      let url = this.$parent.baseUrl + 'exam/GetExamQuestions/'+this.$store.getters.user.userId+'/'+this.$store.getters.user.standardId;
      let header = {'Authorization': 'Bearer ' + this.$store.getters.token, 'school-code': this.$store.getters.schoolcode}
      axios.get(url, {headers: header}).then(function(result) {
        self.$parent.showLoader = false;
        if(result.data && result.data.examData && result.data.examData.length > 0 && result.data.exam) {
          self.examDetails = result.data.exam;
          self.showIdentityWebcam();
        }
      }).catch(function(){ self.$parent.showLoader = false; });
    },

    showIdentityWebcam: function(){
      if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false
        })
        .then(stream => {
            document.getElementById('candidate_video').srcObject = stream;
        })

        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false
        })
        .then(stream => {
            document.getElementById('identity_video').srcObject = stream;
        })
    }
    },

    logActivities: function(activityType) {
      let header = {'Authorization': 'Bearer ' + this.$store.getters.token, 'school-code': this.$store.getters.schoolcode}

      let payload = {ActivityType: activityType, Location: this.location, IPAddress: this.ipAddress};
      
      axios.post(this.$parent.baseUrl+'exam/AddActivities/'+this.$store.getters.user.userId+'/'+this.examDetails.scheduleOnlineExamId, payload, { headers: header })
      .then(function(result) { }).catch(function(err){ });
    },

    captureCandidate() {
      let candidate_canvas = document.getElementById("candidate_canvas");
      let candidate_video = document.getElementById("candidate_video");

        candidate_canvas.width = candidate_video.width;
        candidate_canvas.height = candidate_video.height;
        
        let ctx = candidate_canvas.getContext("2d");

        const dpr = window.devicePixelRatio;
        const rect = candidate_canvas.getBoundingClientRect();

        // Set the "actual" size of the canvas
        candidate_canvas.width = rect.width * dpr;
        candidate_canvas.height = rect.height * dpr;

        // Scale the context to ensure correct drawing operations
        ctx.scale(dpr, dpr);

        // Set the "drawn" size of the canvas
        candidate_canvas.style.width = `${rect.width}px`;
        candidate_canvas.style.height = `${rect.height}px`;

        ctx.drawImage(candidate_video, 0, 0, candidate_video.width, candidate_video.height);
        ctx.font = "18px Verdana";
        ctx.fillText(this.$store.getters.user.studentName, 10, 30);

        candidate_container.scroll({left:candidate_video.width, top: 0, behavior: 'smooth'});
    },

    captureCandidateAgain() {
        candidate_container.scroll({left: 0, top: 0, behavior: 'smooth'});
    },

    saveCandidate() {      
      let candidate_canvas = document.getElementById("candidate_canvas");
      let urlData = candidate_canvas.toDataURL("image/png");

      let fd = new FormData();
      fd.append("base64Image", urlData);
      let url = `${this.$parent.baseUrl}exam/SaveIdentityImages/${this.examDetails.examName.trim()}/${this.$store.getters.user.studentName}`;
      let header = {'Authorization': 'Bearer ' + this.$store.getters.token, 'school-code': this.$store.getters.schoolcode}

      axios.post(url, fd, {headers: header}).then(function (result) {});
      
      this.logActivities(ExamActivityLogs.CANDIDATE_IMAGE);

      let candidate_video = document.getElementById("candidate_video");
      candidate_container.scroll({left: candidate_video.width*2, top: 0, behavior: 'smooth'});
    },

    captureIdentity() {
      let identity_canvas = document.getElementById("identity_canvas");
      let identity_video = document.getElementById("identity_video");
     
      identity_canvas.width = identity_video.width;
      identity_canvas.height = identity_video.height;
      
        let ctx = identity_canvas.getContext("2d");

        const dpr = window.devicePixelRatio;
        const rect = identity_canvas.getBoundingClientRect();

        // Set the "actual" size of the canvas
        identity_canvas.width = rect.width * dpr;
        identity_canvas.height = rect.height * dpr;

        // Scale the context to ensure correct drawing operations
        ctx.scale(dpr, dpr);

        // Set the "drawn" size of the canvas
        identity_canvas.style.width = `${rect.width}px`;
        identity_canvas.style.height = `${rect.height}px`;

        ctx.drawImage(identity_video, 0, 0, identity_video.width, identity_video.height);
        ctx.font = "18px Verdana";
        ctx.fillText(this.$store.getters.user.studentName.trim()+ "'s Identity", 10, 30);
        
        candidate_container.scroll({left:identity_video.width*3, top: 0, behavior: 'smooth'});
    },

    captureIdentityAgain() {
        let identity_video = document.getElementById("identity_video");
        candidate_container.scroll({left: identity_video.width*2, top: 0, behavior: 'smooth'});
    },

    saveIdentity() {  
      let self = this; 
      let identity_canvas = document.getElementById("identity_canvas");
      let urlData = identity_canvas.toDataURL("image/png");

      let fd = new FormData();
      fd.append("base64Image", urlData);
      let url = `${this.$parent.baseUrl}exam/SaveIdentityImages/${this.examDetails.examName.trim()}/${this.$store.getters.user.studentName}`;
      let header = {'Authorization': 'Bearer ' + this.$store.getters.token, 'school-code': this.$store.getters.schoolcode}
      axios.post(url, fd, {headers: header}).then(function (result) {});

      this.logActivities(ExamActivityLogs.IDENTITY_IMAGE);

      self.$router.push({ name: 'Exam' });
    },

    skipToExam() {
        this.$router.push({ name: 'Exam' });
    }
  },
};
</script>

<style scoped>
.identity-container {
    margin: 20px;
    height: calc(100% - 40px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.identity-container.no-exam-found {
  justify-content: center;
}

 [hidden] {
    display: none !important;
}
</style>