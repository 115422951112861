<template>
  <div id="app">
    <div id="page" class="h-100">
      <div v-show="showLoader" id="page-preloader">
            <div class="loader-main">
              <div class="preload-spinner"></div>
              <div class="preload-label">Loading...</div>
            </div>
      </div>

      <div class="page-bg"><div></div></div>
      
      <router-view />

      <div class="menu-hider"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "App",
  data() {
    return {
      baseUrl: 'https://examapi.nmediasoft.com/api/',
      //baseUrl: 'https://localhost:44372/api/',
      showLoader: false
    };
  },
  computed: {
    userData() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    schoolcode() {
      return this.$store.getters.schoolcode;
    },
  },
  mounted() {
    let self = this;
    let count=0;
    window.onfocus = function() {
      if(self.userData) {
        let url = self.baseUrl + 'exam/CheckUser';
        let header = {'Authorization': 'Bearer ' + self.token, 'school-code': self.schoolcode}
        let fd = new FormData();
        fd.append('guid', self.userData.guid);
        fd.append('userId', self.userData.userId);
        axios.post(url, fd, {headers: header}).catch(error=> {
          self.$store.dispatch('setToken', '');
          sessionStorage.removeItem('auth');
          self.$store.dispatch('setUser', '');
          sessionStorage.removeItem('user');
          self.$store.dispatch('setSchoolcode', '');
          sessionStorage.removeItem('schoolcode');
          self.$router.push({ name: 'Login' });
        });
      }
    };
  }
}
</script>

<style>
 .h-100 {
   height: 100% !important;
 }
 .preload-label {
    position: absolute;
    left: calc(50% - 17px);
    margin-left: -20px;
    top: calc(50% + 50px);
    margin-top: -20px;
    font-size: 20px;
 }
 #app {
   height: 100%;
 }
</style>
