<template src="./Exam.html"></template>

<style scoped src="./Exam.css"></style>

<style>
.adjusted-height-body {
  height: calc(100% - 60px) !important;
  margin-top: 60px !important;
}
</style>

<script>
var self;
import axios from "axios";
import moment from "moment";
import { ExamActivityLogs } from "../../enum";

export default {
  name: "Exam",
  data() {
    return {
      questions: [],
      QuestionIndexList: [],
      IsSaveDisabled: false,
      AlphabetOption: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      isGuidelineShow: true,
      examDetails: "",
      startTime: "Please wait...",
      examDateTime: "",
      IsStartButtonEnable: false,
      remainingTime: "calculating...",
      IsExamOver: false,
      UserPohtoInterval: null,
      IsBlinkWatch: false,
      video: "",
      canvas: "",
      UploadedFiles: [],
      PreviewUrl: [],
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      elmnt: "",
      IsQNoClick: false,
      IsShowQuestionDetails: true,
      IsExpandedQuestionDetails: false,
      examParts: [],
      examSections: [],
      markForReviewSessionStorageKey: "markforreviewsessionstorage",
      location: "",
      ipAddress: "",
      webcamPermission: true,
      isFaceDetecting: true,
      isMultipleFaceDetecting: false,
    };
  },
  created() {
    self = this;
    this.GetExamQuestions();
    navigator.geolocation.getCurrentPosition(function (geo) {
      self.location = geo.coords.latitude + "," + geo.coords.longitude;
    });

    axios.get("https://api.ipify.org/?format=json").then(function (res) {
      self.ipAddress = res.data.ip;
    });
  },
  computed: {
    userData() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    schoolcode() {
      return this.$store.getters.schoolcode;
    },
    activeQuestion() {
      if (self.questions.length > 0) {
        let question = self.questions.find((d) => d.IsActive);
        question.IsVisited = true;

        return question;
      }
    },
    notVisitedQustion() {
      let question = self.QuestionIndexList.filter(
        (d) => d.IsVisited || d.IsAnswered
      );
      return self.QuestionIndexList.length - question.length;
    },
    compulsoryQustion() {
      let question = self.QuestionIndexList.filter((d) => d.IsCompulsory);
      return question.length;
    },
    answeredQustion() {
      let question = self.QuestionIndexList.filter((d) => d.IsAnswered);
      return question.length;
    },
    notAnsweredQustion() {
      let question = self.QuestionIndexList.filter((d) => d.IsAnswered);
      return self.QuestionIndexList.length - question.length;
    },
    isMarkedForReviewQustion() {
      let question = self.QuestionIndexList.filter(
        (d) => d.IsMarkedForReview && !d.IsAnswered
      );
      return question.length;
    },
    answeredAndMarkedForReviewQustion() {
      let question = self.QuestionIndexList.filter(
        (d) => d.IsMarkedForReview && d.IsAnswered
      );
      return question.length;
    },
    questionIndexList() {
      if (self.QuestionIndexList.length > 0) {
        self.QuestionIndexList.forEach((element) => {
          element.IsAnswered = false;
          let q = self.questions.find(
            (d) => d.QuestionId == element.QuestionId
          );
          if (q) {
            element.IsVisited = q.IsVisited;

            q.AnsweredValues.forEach((ans) => {
              if (element.QuestionsNo == ans.NoOfQuestions) {
                element.IsAnswered = true;
              }
            });
          }
        });
      }
      return self.QuestionIndexList;
    },
  },
  mounted() {
    document
      .getElementsByTagName("body")[0]
      .classList.add("adjusted-height-body");
  },
  beforeDestroy() {
    clearInterval(self.UserPohtoInterval);
    document
      .getElementsByTagName("body")[0]
      .classList.remove("adjusted-height-body");
  },
  methods: {
    GetExamQuestions: function (e) {
      self.$parent.showLoader = true;
      let url =
        this.$parent.baseUrl +
        "exam/GetExamQuestions/" +
        self.userData.userId +
        "/" +
        self.userData.standardId;
      let header = {
        Authorization: "Bearer " + self.token,
        "school-code": self.schoolcode,
      };
      axios
        .get(url, { headers: header })
        .then(function (result) {
          if (
            result.data &&
            result.data.examData &&
            result.data.examData.length > 0 &&
            result.data.exam
          ) {
            self.showExamGuidelinesDetails(result.data.exam);

            let questionsOptionsData = result.data.examData;

            let examPart = questionsOptionsData.map((data) => data.examPart);
            self.examParts = examPart.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });

            let examSection = questionsOptionsData.map(
              (data) => data.examSection
            );
            self.examSections = examSection.filter(function (
              value,
              index,
              self
            ) {
              return self.indexOf(value) === index;
            });

            let qArray = questionsOptionsData.map((data) => data.questionId);
            qArray = qArray.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });

            let userAnswerList =
              result.data && result.data.answers && result.data.answers
                ? result.data.answers
                : [];
            let QuestionIndex = 0;
            qArray.forEach((QuestionId, index) => {
              let questionArray = questionsOptionsData.filter(
                (data) => QuestionId === data.questionId
              );
              if (questionArray.length > 0) {
                let markForReviewSessionStoragevalue = sessionStorage.getItem(
                  self.markForReviewSessionStorageKey
                );
                markForReviewSessionStoragevalue =
                  markForReviewSessionStoragevalue
                    ? JSON.parse(markForReviewSessionStoragevalue)
                    : [];

                let displayQuestionNo = [];
                for (
                  let index = 0;
                  index < questionArray[0].noOfQuestions;
                  index++
                ) {
                  self.QuestionIndexList.push({
                    QuestionIndex: ++QuestionIndex,
                    QuestionId: questionArray[0].questionId,
                    QuestionsNo: index + 1,
                    IsAnswered: false,
                    IsCompulsory: questionArray[0].isCompulsory,
                    IsVisited: false,
                    IsMarkedForReview:
                      markForReviewSessionStoragevalue.indexOf(
                        questionArray[0].questionId
                      ) >= 0,
                    examPart: questionArray[0].examPart,
                    examSection: questionArray[0].examSection,
                  });
                  displayQuestionNo.push(QuestionIndex);
                }

                let answeredValues = [];
                if (userAnswerList.length > 0) {
                  let ansList = userAnswerList.filter(
                    (d) => d.questionId == QuestionId
                  );
                  ansList.forEach((element) => {
                    answeredValues.push({
                      QuestionId: element.questionId,
                      NoOfQuestions: element.noOfQuestion,
                      Answer: element.answer,
                    });
                  });

                  let questionOnlyPdf = questionsOptionsData.filter(
                    (data) => data.questionType === "only_pdf"
                  );
                  if (questionOnlyPdf.length > 0) {
                    let ansOnlyPdf = userAnswerList.filter(
                      (d) => d.questionId == questionOnlyPdf[0].questionId
                    );
                    let arrFileUrl = ansOnlyPdf[0].answer.split(",");
                    self.PreviewUrl = [];
                    arrFileUrl.forEach((e) => {
                      e = location.origin + "/UploadFiles/" + e;
                      self.PreviewUrl.push({
                        ImageUrl: e,
                      });
                    });
                  }
                }

                let answerInputList = [];
                let options = questionArray.map(function (option) {
                  return {
                    QuestionId: option.questionId,
                    OptionValue: option.optionValue,
                    OptionLabel: option.optionLabel,
                    Answer: "",
                  };
                });
                if (questionArray[0].questionType == "one_word") {
                  for (
                    let index = 1;
                    index < questionArray[0].noOfQuestions;
                    index++
                  ) {
                    let opt = JSON.stringify(options[0]);
                    options.push(JSON.parse(opt));
                  }
                } else if (
                  questionArray[0].questionType == "matching_option" ||
                  questionArray[0].questionType == "diagram_image" ||
                  questionArray[0].questionType == "multi_select" ||
                  questionArray[0].questionType == "paragraph" ||
                  questionArray[0].questionType == "pdf_with_option"
                ) {
                  for (
                    let index = 1;
                    index <= questionArray[0].noOfQuestions;
                    index++
                  ) {
                    answerInputList.push({
                      QuestionId: questionArray[0].questionId,
                      QuestionsNo: index,
                      Answer: "",
                    });
                  }
                }
                let questions = {
                  QuestionId: questionArray[0].questionId,
                  QuestionType: questionArray[0].questionType,
                  Question: questionArray[0].question,
                  NoOfQuestions: questionArray[0].noOfQuestions,
                  Answer: "",
                  Marks: questionArray[0].marks,
                  MinusMarks: questionArray[0].minusMarks,
                  Options: options,
                  displayQuestionNo: displayQuestionNo,
                  IsActive: index == 0,
                  IsVisited: index == 0,
                  AnswerInputList: answerInputList,
                  AnsweredValues: answeredValues,
                  IsImage: questionArray[0].isImage,
                  ExamPart: questionArray[0].examPart,
                  ExamSection: questionArray[0].examSection,
                };

                if (index == 0) self.getSetAnswers(questions, "set");

                self.questions.push(questions);
              }
            });
          }
          self.$parent.showLoader = false;
        })
        .catch(function (error) {
          self.$parent.showLoader = false;
          if (error && error.response && error.response.status == 401) {
            sessionStorage.removeItem("auth");
            self.$store.dispatch("setToken", "");
            self.$router.push({ name: "Login" });
          }
        });
    },
    showExamGuidelinesDetails: function (exam) {
      self.examDetails = exam;
      self.examDateTime =
        moment(self.examDetails.examDate, "YYYY-MM-DD[T]00:00:00").format(
          "DD/MM/YYYY"
        ) +
        " " +
        self.examDetails.startTime;
      self.examStartIn();
    },
    addFiles() {
      this.$refs.inpUploadfiles.click();
    },
    handleFileUploads: function (e) {
      let uploadedFiles = this.$refs.inpUploadfiles.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.UploadedFiles.push(uploadedFiles[i]);

        const reader = new FileReader();
        reader.onload = (e) => {
          // this.PreviewUrlPresc.push(e.target.result);
          self.PreviewUrl.push({
            ImageUrl: e.target.result,
          });
        };
        reader.readAsDataURL(uploadedFiles[i]);
      }
      //var files = e.target.files || e.dataTransfer.files;
      //self.PrescriptionFile = files[0];
    },
    removeFile(key) {
      this.UploadedFiles.splice(key, 1);
      this.PreviewUrl.splice(key, 1);
    },

    examStartIn: function () {
      let examTime = self.examDateTime;
      let currentDateTime = moment(self.examDetails.cdt);
      let timeInterval = setInterval(function () {
        let timeDiff = moment(examTime, "DD/MM/YYYY hh:mm A").diff(
          currentDateTime
        );
        if (
          timeDiff < 0 &&
          moment(
            moment(examTime, "DD/MM/YYYY hh:mm A").add(
              "m",
              self.examDetails.duration
            )
          ).diff(currentDateTime) > 0
        ) {
          self.startTime = "Started";
          clearInterval(timeInterval);
          self.IsStartButtonEnable = true;
        } else if (timeDiff < 0) {
          self.startTime = "Exam Over";
        } else {
          var duration = moment.duration(timeDiff);
          self.startTime =
            self.formatTime(duration.hours()) +
            ":" +
            self.formatTime(duration.minutes()) +
            ":" +
            self.formatTime(duration.seconds());
        }

        currentDateTime = currentDateTime.add("s", 1);
      }, 1000);
    },

    formatTime: function (time) {
      if (time.toString().length == 1) return "0" + time;
      return time;
    },
    StartExam: function () {
      if (!self.IsStartButtonEnable) return;

      self.LogActivities(ExamActivityLogs.START, null);

      self.isGuidelineShow = false;
      let examTime = self.examDateTime;
      let currentDateTime = moment(self.examDetails.cdt);
      let timeInterval = setInterval(function () {
        let timeDiff = moment(
          moment(examTime, "DD/MM/YYYY hh:mm A").add(
            "m",
            self.examDetails.duration
          )
        ).diff(moment(currentDateTime));
        if (timeDiff > 0) {
          let duration = moment.duration(timeDiff);
          let hours = duration.hours(),
            minutes = duration.minutes();
          self.remainingTime =
            self.formatTime(hours) +
            ":" +
            self.formatTime(minutes) +
            ":" +
            self.formatTime(duration.seconds());
          if (hours <= 0 && minutes < 10 && !self.IsBlinkWatch) {
            self.IsBlinkWatch = true;
          }
        } else {
          self.remainingTime = "00:00:00";
          self.IsExamOver = true;
          self.IsBlinkWatch = false;
          clearInterval(timeInterval);

          self.$router.push({
            name: "ExamFinish",
            params: {
              totalQuestion: self.QuestionIndexList.length,
              answered: self.answeredQustion,
              notVisited: self.notVisitedQustion,
            },
          });
        }

        currentDateTime = currentDateTime.add("s", 1);
      }, 1000);
      //self.dragElement();
      self.startVideo();
      document.getElementsByClassName("logout-icon")[0].style.display = "block";
    },

    LogActivities: async function (activityType, faceDetection) {
      let header = {
        Authorization: "Bearer " + self.token,
        "school-code": self.schoolcode,
      };

      let payload = {
        ActivityType: activityType,
        Location: self.location,
        IPAddress: self.ipAddress,
      };
      if (faceDetection !== null && faceDetection !== undefined) {
        // Allow 0 in if
        payload.FaceDetection = faceDetection;
      }

      axios
        .post(
          self.$parent.baseUrl +
            "exam/AddActivities/" +
            self.userData.userId +
            "/" +
            self.examDetails.scheduleOnlineExamId,
          payload,
          { headers: header }
        )
        .then(function (result) {})
        .catch(function (err) {});
    },

    Logout: async function (e) {
      let confirmMsg = await confirm("Are you sure, Do you want to finish exam?");
      if (confirmMsg) {
        if (self.activeQuestion) {
          let data = self.questionIndexList.find(
            (d) => d.QuestionId == self.activeQuestion.QuestionId
          );
          if (data.IsAnswered != undefined) {
            self.IsQNoClick = true;
            await self.SaveAnswers();
          }
        }

        var LastQuestionNo =
          document.getElementsByClassName("question-no").length;
        var AnsweredCount = 0;
        for (let i = 0; i < self.QuestionIndexList.length; i++) {
          if (self.QuestionIndexList[i].IsAnswered) {
            AnsweredCount += 1;
          }
        }

        let compulsoryPendingQuestions = self.QuestionIndexList.filter((x) => {
          return !x.IsAnswered && x.IsCompulsory;
        });

        this.$router.push({
          name: "ExamFinish",
          params: {
            totalQuestion: self.QuestionIndexList.length,
            answered: self.answeredQustion,
            notVisited: self.notVisitedQustion,
          },
        });

        // if(compulsoryPendingQuestions.length > 0){
        //   alert('Please attend all compalsory questions');
        //   return;
        // }

        // var PendingCount = parseInt(LastQuestionNo) - AnsweredCount;
        // alert("Total Attempted Questions :  "+AnsweredCount + "\n" + "Total Pending Questions : "+ PendingCount)

        // this.$store.dispatch('setToken', '');
        // sessionStorage.removeItem('auth');
        // this.$store.dispatch('setUser', '');
        // sessionStorage.removeItem('user');
        // this.$store.dispatch('setSchoolcode', '');
        // sessionStorage.removeItem('schoolcode');
        // this.$router.push({ name: 'Login' });
      }
    },

    AnswerSelected: async function () {
      self.IsQNoClick = true;
      await self.SaveAnswers();

      self.getSetAnswers(self.activeQuestion, "set");
    },

    changeQuestion: function (data) {
      let displayQuestionNo = self.activeQuestion.displayQuestionNo;
      var LastQuestionNo =
        document.getElementsByClassName("question-no").length;
      if (parseInt(LastQuestionNo) == parseInt(data.QuestionIndex)) {
        document.getElementsByClassName("save-answer")[0].textContent = "Save";
      } else {
        document.getElementsByClassName("save-answer")[0].textContent =
          "Save & Next";
      }

      // This is for save answer on question change
      // self.questions.forEach(element => {
      //   if (data.IsAnswered != undefined && element.IsActive) {
      //     self.IsQNoClick = true;
      //     document.getElementsByClassName("save-answer")[0].click();
      //   }
      // });

      self.questions.forEach((element) => {
        element.IsActive = false;
        if (element.QuestionId == data.QuestionId) {
          element.IsActive = true;
          self.getSetAnswers(element, "set");
        }
      });
    },

    nextQuestion: function () {
      let index = self.questions.indexOf(self.activeQuestion);
      if (index < self.questions.length - 1) {
        let IndexedQ = self.questions[index + 1];
        self.changeQuestion({ QuestionId: IndexedQ.QuestionId });
      }
    },

    getDisplayQuestionNo: function () {
      if (self.questions.length == 0) return null;

      let activeQuestion = self.questions.find((d) => d.IsActive);
      return "Question " + activeQuestion.displayQuestionNo.join(" - ");
    },

    SaveAnswers: async function () {
      let self = this;

      let QuestionType = self.activeQuestion.QuestionType;
      let displayQuestionNo = self.activeQuestion.displayQuestionNo;
      let currentAnswer = self.activeQuestion.AnsweredValues;
      let answers = self.getSetAnswers(self.activeQuestion, "get");
      let questionId = self.activeQuestion.QuestionId;
      self.activeQuestion.AnsweredValues = [];
      var LastQuestionNo =
        document.getElementsByClassName("question-no").length;

      if (QuestionType == "only_pdf") {
        let url =
          this.$parent.baseUrl +
          "exam/UploadUserfiles/" +
          self.activeQuestion.QuestionId +
          "/" +
          self.userData.userId +
          "/" +
          self.examDetails.scheduleOnlineExamId;
        let header = {
          Authorization: "Bearer " + self.token,
          "school-code": self.schoolcode,
        };
        let formData = new FormData();

        for (var i = 0; i < this.UploadedFiles.length; i++) {
          let filepresc = this.UploadedFiles[i];

          //formData.append('filesPresc[' + i + ']', filepresc);
          formData.append("filesPresc", filepresc);
        }

        try {
          let result = await axios.post(url, formData, { headers: header });
          let currentQuestion = self.questions.find(
            (d) => d.QuestionId == questionId
          );
          if (result && result.data) {
            if (currentQuestion) {
              currentQuestion.AnsweredValues = currentAnswer;
            }
            if (result.data > 0) {
              alert("Files uploaded successfully.");
            } else {
              alert(result.data);
            }
          }
        } catch (error) {
          let currentQuestion = self.questions.find(
            (d) => d.QuestionId == questionId
          );
          if (currentQuestion) {
            currentQuestion.AnsweredValues = currentAnswer;
          }
          self.resetAnswer(questionId);
          if (error && error.response && error.response.status == 401) {
            self.$store.dispatch("setToken", null);
            sessionStorage.removeItem("auth");
            self.$router.push({ name: "Login" });
          } else alert(errorMsg);
        }

        return;
      }

      if (answers.length > 0) {
        let errorMsg =
          "Error in save question " +
          displayQuestionNo.join(",") +
          ".\nPlease check internet connection.";
        self.activeQuestion.AnsweredValues = answers;
        let url =
          this.$parent.baseUrl +
          "exam/SaveAnswers/" +
          self.activeQuestion.QuestionId +
          "/" +
          self.userData.userId +
          "/" +
          self.examDetails.scheduleOnlineExamId;
        let header = {
          Authorization: "Bearer " + self.token,
          "school-code": self.schoolcode,
        };
        let xmlAnswer = "";
        self.activeQuestion.AnsweredValues.forEach((element) => {
          xmlAnswer +=
            "<Answers><Answer>" +
            element.Answer +
            "</Answer><NoOfQuestion>" +
            element.NoOfQuestions +
            "</NoOfQuestion></Answers>";
        });
        xmlAnswer = "asnwerXml=" + xmlAnswer;
        try {
          // Check if Section B is there and number of question to be attend is limited
          if (
            self.examDetails.sectionBQuetionNo &&
            self.examDetails.sectionBQuetionNo > 0 &&
            self.activeQuestion.ExamSection == "Section B"
          ) {
            let validateSectionBQuestion = self.questionIndexList.filter(
              (d) =>
                d.examPart == self.activeQuestion.ExamPart &&
                d.examSection == "Section B" &&
                d.IsAnswered
            );
            if (
              validateSectionBQuestion &&
              validateSectionBQuestion.length >
                self.examDetails.sectionBQuetionNo
            ) {
              setTimeout(() => {
                let currentQuestion = self.questions.find(
                  (d) => d.QuestionId == questionId
                );
                if (currentQuestion) {
                  currentQuestion.Answer = "";
                  currentQuestion.AnsweredValues = [];
                }
              }, 200);
              alert(
                "You can answer only " +
                  self.examDetails.sectionBQuetionNo +
                  " Qustions of Section B"
              );
              return;
            }
          }

          let result = await axios.post(url, xmlAnswer, { headers: header });
          if (!(result && result.data)) {
            let currentQuestion = self.questions.find(
              (d) => d.QuestionId == questionId
            );
            if (currentQuestion) {
              currentQuestion.AnsweredValues = currentAnswer;
            }
            self.resetAnswer(questionId);
            alert(errorMsg);
          } else {
            if (
              parseInt(LastQuestionNo) <=
              parseInt(self.activeQuestion.displayQuestionNo[0])
            ) {
              if (document.getElementsByClassName("save-answer").length > 0)
                document.getElementsByClassName("save-answer")[0].textContent =
                  "Save";
            } else {
              if (document.getElementsByClassName("save-answer").length > 0)
                document.getElementsByClassName("save-answer")[0].textContent =
                  "Save & Next";
            }

            if (!self.IsQNoClick) self.nextQuestion();
          }
        } catch (err) {
          let currentQuestion = self.questions.find(
            (d) => d.QuestionId == questionId
          );
          if (currentQuestion) {
            currentQuestion.AnsweredValues = currentAnswer;
          }
          self.resetAnswer(questionId);
          if (err && err.response && err.response.status == 401) {
            self.$store.dispatch("setToken", null);
            sessionStorage.removeItem("auth");
            self.$router.push({ name: "Login" });
          } else alert(errorMsg);
        }
      } else {
        if (self.IsQNoClick == false) {
          alert("Please select answer");
        }
      }
      self.IsQNoClick = false;
    },

    resetAnswer: function (questionId) {
      let userAnswer = self.questions.find((d) => d.QuestionId == questionId);
      if (userAnswer) {
        // userAnswer.AnsweredValues = [];
        self.getSetAnswers(userAnswer, "set");
      }
    },

    ClearAnswer: async function () {
      let url =
        self.$parent.baseUrl +
        "exam/RemoveAnswer/" +
        self.activeQuestion.QuestionId +
        "/" +
        self.userData.userId;
      let header = {
        Authorization: "Bearer " + self.token,
        "school-code": self.schoolcode,
      };
      let result = await axios.post(url, null, { headers: header });

      let currentQuestion = self.questions.find(
        (d) => d.QuestionId == self.activeQuestion.QuestionId
      );
      if (currentQuestion) {
        currentQuestion.AnsweredValues = [];

        let markForReviewSessionStoragevalue = sessionStorage.getItem(
          self.markForReviewSessionStorageKey
        );
        markForReviewSessionStoragevalue = markForReviewSessionStoragevalue
          ? JSON.parse(markForReviewSessionStoragevalue)
          : [];
        markForReviewSessionStoragevalue =
          markForReviewSessionStoragevalue.filter(
            (x) => x != currentQuestion.QuestionId
          );
        sessionStorage.setItem(
          self.markForReviewSessionStorageKey,
          JSON.stringify(markForReviewSessionStoragevalue)
        );
      }

      self.QuestionIndexList.forEach((element) => {
        if (self.activeQuestion.QuestionId == element.QuestionId)
          element.IsMarkedForReview = false;
      });

      self.getSetAnswers(self.activeQuestion, "set");
    },

    MarkForReview: function () {
      let markForReviewSessionStoragevalue = sessionStorage.getItem(
        self.markForReviewSessionStorageKey
      );
      markForReviewSessionStoragevalue = markForReviewSessionStoragevalue
        ? JSON.parse(markForReviewSessionStoragevalue)
        : [];

      self.QuestionIndexList.forEach((element) => {
        if (self.activeQuestion.QuestionId == element.QuestionId) {
          element.IsMarkedForReview = true;
          markForReviewSessionStoragevalue.push(element.QuestionId);
        }
      });

      sessionStorage.setItem(
        self.markForReviewSessionStorageKey,
        JSON.stringify(markForReviewSessionStoragevalue)
      );
    },

    SaveAnswersAndMarkForReview: async function () {
      self.MarkForReview();
      await self.SaveAnswers();
    },

    getSetAnswers: function (d, type) {
      let answers = [];
      let no = 1;
      switch (d.QuestionType) {
        case "single_answer":
          if (d.Answer && type == "get") {
            answers.push({
              QuestionId: d.QuestionId,
              NoOfQuestions: 1,
              Answer: d.Answer,
            });
          } else if (type == "set") {
            d.Answer = "";
            if (d.AnsweredValues.length > 0)
              d.Answer = d.AnsweredValues[0].Answer;
          }
          break;

        case "multi_select":
          d.Options.forEach((element) => {
            if (type == "get") {
              if (element.Answer) {
                answers.push({
                  QuestionId: element.QuestionId,
                  NoOfQuestions: no,
                  Answer: element.OptionValue,
                });
                no++;
              }
            } else if (type == "set") {
              element.Answer = false;
              if (d.AnsweredValues.length > 0) {
                let op = d.AnsweredValues.find(
                  (item) => item.Answer == element.OptionValue
                );
                if (op) element.Answer = true;
              }
            }
          });
          break;
        case "one_word":
          d.Options.forEach((element, i) => {
            if (type == "get") {
              if (element.Answer) {
                answers.push({
                  QuestionId: element.QuestionId,
                  NoOfQuestions: i + 1,
                  Answer: element.Answer,
                });
              }
            } else if (type == "set") {
              element.Answer = "";
              if (d.AnsweredValues.length > 0) {
                let op = d.AnsweredValues.find(
                  (item) => item.NoOfQuestions == i + 1
                );
                if (op) element.Answer = op.Answer;
              }
            }
          });
          break;
        case "numeric":
          d.Options.forEach((element, i) => {
            if (type == "get") {
              if (element.Answer) {
                answers.push({
                  QuestionId: element.QuestionId,
                  NoOfQuestions: i + 1,
                  Answer: element.Answer,
                });
              }
            } else if (type == "set") {
              element.Answer = "";
              if (d.AnsweredValues.length > 0) {
                let op = d.AnsweredValues.find(
                  (item) => item.NoOfQuestions == i + 1
                );
                if (op) element.Answer = op.Answer;
              }
            }
          });
          break;
        case "matching_option":
        case "paragraph":
        case "diagram_image":
        case "pdf_with_option":
          d.AnswerInputList.forEach((element) => {
            if (type == "get") {
              if (element.Answer) {
                answers.push({
                  QuestionId: element.QuestionId,
                  NoOfQuestions: element.QuestionsNo,
                  Answer: element.Answer,
                });
              }
            } else if (type == "set") {
              element.Answer = "";
              if (d.AnsweredValues.length > 0) {
                let op = d.AnsweredValues.find(
                  (item) => item.NoOfQuestions == element.QuestionsNo
                );
                if (op) element.Answer = op.Answer;
              }
            }
          });
          break;
        default:
          break;
      }
      return answers;
    },
    OnChangeMultiSelectAnswers: function (d) {
      let noq = this.activeQuestion.NoOfQuestions;
      let selectedAnswers = this.activeQuestion.Options.filter(
        (item) => item.Answer
      );
      if (selectedAnswers.length > noq) {
        setTimeout(function () {
          d.Answer = false;
          alert("You can select only " + noq + " correct answer(s)");
        }, 100);
      }
    },
    ShowQuestionDetails: function () {
      this.IsShowQuestionDetails = !this.IsShowQuestionDetails;
    },
    ExpandCollapseQuestionDetails: function () {
      this.IsExpandedQuestionDetails = !this.IsExpandedQuestionDetails;
    },

    dragElement() {
      self.elmnt = document.getElementById("my_draggable_recording");
      if (document.getElementById(self.elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(self.elmnt.id + "header").onmousedown =
          self.dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        self.elmnt.onmousedown = self.dragMouseDown;
      }

      var dragItem = document.querySelector("#my_draggable_recording");
      var secElem = document.querySelector("#my_draggable_recording_header");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      secElem.addEventListener("touchstart", dragStart, false);
      secElem.addEventListener("touchend", dragEnd, false);
      secElem.addEventListener("touchmove", drag, false);

      secElem.addEventListener("mousedown", dragStart, false);
      secElem.addEventListener("mouseup", dragEnd, false);
      secElem.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === secElem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;
      }

      function drag(e) {
        if (active) {
          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      }
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      self.pos3 = e.clientX;
      self.pos4 = e.clientY;
      document.onmouseup = self.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = self.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      self.pos1 = self.pos3 - e.clientX;
      self.pos2 = self.pos4 - e.clientY;
      self.pos3 = e.clientX;
      self.pos4 = e.clientY;
      // set the element's new position:
      self.elmnt.style.top = self.elmnt.offsetTop - self.pos2 + "px";
      self.elmnt.style.left = self.elmnt.offsetLeft - self.pos1 + "px";
    },
    closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    },
    startVideo() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        self.video = document.getElementById("video");
        self.canvas = document.getElementById("canvas");

        navigator.mediaDevices
          .getUserMedia({
            video: true,
            audio: false,
          })
          .then((stm) => {
            if (!self.webcamPermission) {
              self.webcamPermission = true;
            }
            console.log("stream", stm);
            self.video.srcObject = stm;
            Promise.all([
              faceapi.nets.tinyFaceDetector.loadFromUri("/models"), // for build
              //faceapi.nets.tinyFaceDetector.loadFromDisk('/models') // for local run
            ])
              .then(self.takeUserPhoto())
              .catch((err) => {
                console.log("-----Error------------------------------");
              });
          })
          .catch((e) => {
            self.webcamPermission = false;
            alert("Please allow webcam permission.");
          });
      } else {
        alert(
          "Your browser doesn't support web recording.Please use Google Chrome."
        );
      }
    },
    takeUserPhoto() {
      let canvas = document.getElementById("face_detection_canvas");
      const displaySize = {
        width: self.video.width,
        height: self.video.height,
      };
      faceapi.matchDimensions(canvas, displaySize);
      let faceDetectionOptions = new faceapi.TinyFaceDetectorOptions({
        scoreThreshold: 0.1,
      });

      let faceMiscellaneousCount = 0,
        multipleFaceDetection = 0;
      setInterval(async function () {
        const detections = await faceapi.detectAllFaces(
          self.video,
          faceDetectionOptions
        ); //.withFaceLandmarks().withFaceExpressions()

        if (detections.length == 0) {
          faceMiscellaneousCount++;
        } else {
          faceMiscellaneousCount = 0;
        }

        if (detections.length > 1) {
          multipleFaceDetection++;
        } else {
          multipleFaceDetection = 0;
        }

        const resizedDetections = faceapi.resizeResults(
          detections,
          displaySize
        );
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        faceapi.draw.drawDetections(canvas, resizedDetections, {
          withScore: false,
        });

        if (detections.length > 0) self.isFaceDetecting = true;

        if (faceMiscellaneousCount >= 6) {
          // If zero faces found for 6 continue occurance (not found for continue 3 seconds)
          faceMiscellaneousCount = 0;
          self.isFaceDetecting = false;
          self.LogActivities(ExamActivityLogs.WEBCAM, 0);
        }

        if (detections.length <= 1) self.isMultipleFaceDetecting = false;

        if (multipleFaceDetection >= 2) {
          // If multiple faces found for 2 continue occurance
          multipleFaceDetection = 0;
          self.isMultipleFaceDetecting = true;
          self.LogActivities(ExamActivityLogs.WEBCAM, detections.length);
        }
      }, 500);

      let randomInterval = self.getRandomInt(120000, 180000);
      self.UserPohtoInterval = setInterval(function () {
        let ctx = self.canvas.getContext("2d");

        // Set the "actual" size of the canvas
        let dpr = 4;
        self.canvas.width = self.video.width * dpr;
        self.canvas.height = self.video.height * dpr;

        // Scale the context to ensure correct drawing operations
        ctx.scale(dpr, dpr);

        ctx.drawImage(self.video, 0, 0, self.video.width, self.video.height);

        let urlData = self.canvas.toDataURL("image/png");
        self.saveUserPhoto(urlData);
        if (self.IsExamOver) clearInterval(self.UserPohtoInterval);
      }, randomInterval);
    },

    saveUserPhoto(urlData) {
      let fd = new FormData();
      fd.append("base64Image", urlData);
      let url = `${self.$parent.baseUrl}exam/SaveScreenshot/${self.examDetails.examName}/${self.userData.studentName}`;
      let header = {
        Authorization: "Bearer " + self.token,
        "school-code": self.schoolcode,
      };

      axios.post(url, fd, { headers: header }).then(function (result) {});
    },

    getRandomInt(min, max) {
      const minCeiled = Math.ceil(min);
      const maxFloored = Math.floor(max);
      return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
      // The maximum is exclusive and the minimum is inclusive
    },
  },
};
</script>
