<template>
  <div class="page-content header-clear-large">
    <div class="content content-box-full content-box round-medium shadow-huge"
      style="padding-top: 0;">
      <h5 class="cal-sub-title uppercase bg-blue2-dark center-text">Login</h5>
      <div class="login-container">
        <form v-on:submit.prevent="LoginUser">
          <div class="input-style input-style-1 input-required">
            <span v-bind:class="[username ? 'input-style-1-active' : 'input-style-1-inactive']">Username</span>
            <em>required</em>
            <input type="text" v-model="username" placeholder="Username" />
          </div>
          <div class="input-style input-style-1 input-required">
            <span v-bind:class="[password ? 'input-style-1-active' : 'input-style-1-inactive']">Password</span>
            <em>required</em>
            <input type="password" v-model="password" placeholder="Password" />
          </div>
          <div class="input-style input-style-1 input-required">
            <span v-bind:class="[schoolcode ? 'input-style-1-active' : 'input-style-1-inactive']">School Code</span>
            <em>required</em>
            <input type="text" v-model="schoolcode" placeholder="School Code" />
          </div>
          <button type="submit" v-bind:disabled="isLogging" class="button shadow-huge button-m button-round-small bg-blue2-dark">Login</button>
          <span v-show="isInvalid" class="invalid">Invalid User</span>
          <div v-show="isLogging" class="preload-spinner"></div>
        </form>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ExamActivityLogs } from '../enum'

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      schoolcode: '',
      isLogging: false,
      isInvalid: false,
      location: ''
    };
  },
  created() {
    var $this = this;
    navigator.geolocation.getCurrentPosition(function (geo) {
      $this.location = geo.coords.latitude + ',' + geo.coords.longitude;
    });
  },
  methods: {
    LoginUser: function(e) { 
      var self = this;     
      self.isLogging = true;
      self.isInvalid = false;

      let url = this.$parent.baseUrl + 'account/token';
      let data = {UserName: this.username, Password: this.password, SchoolCode: this.schoolcode};
      axios.post(url, data).then(async function(result) {
        let payload = {ActivityType:ExamActivityLogs.LOGIN, Location: self.location, IPAddress:''};
        let header = {'Authorization': 'Bearer ' + result.data.token, 'school-code': self.schoolcode}
 
        try{
        let res = await axios.get('https://api.ipify.org/?format=json');
        payload.IPAddress = res.data.ip
        } catch(er){ }

        axios.post(self.$parent.baseUrl+'exam/AddActivities/'+result.data.user.userId+'/'+result.data.user.scheduleOnlineExamId, payload, { headers: header })
        .then(function(result) { }).catch(function(err){ });

        self.isLogging = false;
        self.$store.dispatch('setToken', result.data.token);
        self.$store.dispatch('setUser', result.data.user);
        self.$store.dispatch('setSchoolcode', self.schoolcode);
        self.$router.push({ name: 'Identity' });
        
      }).catch(function(error) {
        self.isLogging = false;
        self.isInvalid = true;
      });      
    }
  }
};
</script>
<style scoped>
.login-container {
    padding: 10px 22px !important;
}
.page-content {
  height: 100%;
  display: flex;
  padding: 0;
}
.content-box {
    max-width: 400px;
    margin: auto;
    min-width: 300px;
    width: 40%;
}
.button {
    margin: 10px 0 10px 0;
    cursor: pointer;
}
.button:disabled {
  opacity: 0.5;
}
.invalid {
    color: red;
    font-weight: 600;
    float: right;
    padding-top: 15px;;
}
</style>